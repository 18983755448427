import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { ArrowRightCircle } from 'react-bootstrap-icons';

import { socialNetworks, logos, socialLink } from '../data/data';

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  } 

  return (
    
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={() => onUpdateActiveLink('')}>
          <img className="image-logo" src={ logos.tecnotorq } loading="lazy" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>
              HOME
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>
              EMPRESA
            </Nav.Link>
            <Nav.Link as={Link} to="/client" className={activeLink === 'client' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('client')}>
              CLIENTES
            </Nav.Link>
            <Nav.Link as={Link} to="/service" className={activeLink === 'service' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('service')}>
              SERVICIOS
            </Nav.Link>
            <Nav.Link as={Link} to="/product" className={activeLink === 'product' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('product')}>
              PRODUCTOS
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/contact" className={activeLink === 'products' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>TRABAJA CON NOSOTROS</Nav.Link> */}
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href={ socialLink.instagram } target="_blank" rel="noreferrer"><img src={socialNetworks.instagram} loading="lazy" alt="Instagram" /></a>
              <a href={ socialLink.whatsApp } target="_blank" rel="noreferrer"><img src={socialNetworks.whatsApp} loading="lazy" alt="WhatsApp" /></a>
              <a href={ socialLink.linkedin } target="_blank" rel="noreferrer"><img src={socialNetworks.linkedin} loading="lazy" alt="LinkedIn" /></a>
              <a href={ socialLink.facebook } target="_blank" rel="noreferrer"><img src={socialNetworks.facebook} loading="lazy" alt="Facebook" /></a>
            </div>
            <Nav.Link id="link-connect" as={Link} to="/contact" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>
              CONTACTENOS <ArrowRightCircle size={27} id="icon-contact" />
            </Nav.Link>
          </span>
        </Navbar.Collapse>
      </Container>
  </Navbar>
  )
}
