import React from 'react';
import './style/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Home } from "./components/Home";
import { NavBar } from "./components/NavBar";
import { Footer } from "./components/Footer";
import { AboutUs } from './components/AboutUs';
import { Clients } from "./components/Clients";
import { Products } from "./components/Products";
import { Contact } from "./components/Contact";
import { Services } from './components/Services';

function App() {
  return (
    <Router> 
      <div className="App">
        <NavBar />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/client" element={<Clients />} />
            <Route path="/product" element={<Products />} />
            <Route path="/service" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </div>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

//  function App() {
//    return (
//     <div className="App">
//       <NavBar />
//     <div className="content-container">
//           <Home />
//            <AboutUs />
//           <Clients />
//           <Products />
//          <Contact />
//         <Footer />
//       </div>
//     </div>
//   );
//  }

export default App;


