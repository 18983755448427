export const AboutUs = () => {
  return (
      <section className="about" id="about">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="about-bx">
                        <h2 className="cssanimation sequence leFadeInLeft">Quiénes Somos</h2>
                        <p> 
                            Nacida en el año de 2022, En <strong>Tecnotorq</strong>, nos dedicamos apasionadamente a proporcionar soluciones
                            integrales en calidad, productividad y ergonomía para industria de manufactura, de energía, alimenticia, metalmecánica
                            y demás sectores industriales. Con sede en la ciudad de Medellín cubrimos las principales capitales y polos industriales de
                            Colombia. Nuestro director comercial posee 20 años de experiencia en implementación de proyectos en la industria de
                            ensamble de juntas rosqueadas. Desde nuestro inicio nos hemos comprometido a ofrecer productos y servicios de la más
                            alta calidad.
                        </p>
                         <p>
                            <strong>Nuestra Misión:</strong> Facilitar el acceso a una matriz tecnológica de vanguardia, garantizando la continuidad operativa y la eficiencia de su producción  contribuyendo así al éxito y crecimiento de sus negocios. Es misión de Tecnotorq además tornar el mercado industrial colombiano un jugador de talla mundial por medio de competitividad.
                        </p>
                        <p>
                            <strong>Nuestro Compromiso:</strong> Mantenemos una relación estrecha con fabricantes y proveedores líderes en la industria, lo que nos permite ofrecer una amplia gama de herramientas para satisfacer las necesidades más exigentes de nuestros clientes. Nos esforzamos por ser un socio confiable y de confianza, brindando asesoramiento técnico y atención personalizada en cada requerimiento.                          
                        </p>
                        <p>
                            <strong>Nuestros Clientes:</strong> Nos enorgullece servir a una amplia variedad de industrias, desde la construcción y la agricultura hasta la minería y la manufactura. Nuestros clientes confían en nosotros para proporcionar repuestos de calidad que mantengan sus operaciones en funcionamiento de manera eficiente y rentable.
                        </p>
                        <p>
                            Nos apasiona lo que hacemos y estamos comprometidos con la satisfacción total del cliente. Estamos aquí para ser su socio de confianza en herramientas y servicios, !contáctanos hoy mismo y descubre cómo podemos ayudarte a alcanzar tus objetivos!!
                        </p>
                    </div>
                </div>
            </div>
        </div>  
    </section>
  )
}
