import React from "react";
import Carousel from "react-multi-carousel";
import Card from 'react-bootstrap/Card';
import "react-multi-carousel/lib/styles.css";

import { tools } from '../data/data';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export const CarruselServices = () => {
  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={false}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {tools.map((tool) => {
          return (
            <div className="slider" key={ tool.id }>
                <Card className="custom-card">
                    <Card.Img variant="top" src={ tool.imgUrl } loading="lazy" />
                    <Card.Body>
                        <Card.Title> { tool.title } </Card.Title>
                        <Card.Text>{ tool.description }</Card.Text>
                    </Card.Body>
                </Card>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};




