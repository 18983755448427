import Carousel from 'react-multi-carousel';
import 'animate.css';
import 'react-multi-carousel/lib/styles.css';

import { brands } from '../data/data';

export const Clients = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="client" id="client">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="client-bx wow zoomIn animate__fadeInLeft">
              <h2>Nuestros clientes</h2>
              <p>
                En <strong>Tecnotorq</strong>, nos enorgullece colaborar con algunas de las marcas más destacadas en la industria. Creemos que la calidad de nuestro trabajo se refleja en la confianza que nuestros clientes depositan en nosotros día tras día, 
                basados en nuestro compromiso con la excelencia y la satisfacción del cliente. Desde empresas emergentes hasta líderes del sector, nos esforzamos por ofrecer soluciones que cumplan y superen las expectativas.
                <br></br>
                Nuestra prioridad es establecer relaciones sólidas y mutuamente beneficiosas con cada cliente, trabajando codo a codo para alcanzar sus objetivos y contribuir al éxito de sus proyectos.
               </p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme client-slider animate__fadeInLeft ">
                <div className="item">
                    <img src={ brands.yamaha } loading="lazy" alt={ 'Yamaha' } />
                </div>
                <div className="item">
                    <img src={ brands.akt } loading="lazy" alt={ 'Akt' }  />
                </div>
                <div className="item">
                    <img src={ brands.suzuki } loading="lazy" alt={ 'Suzuki' } />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>  
    </section>
  )
}
