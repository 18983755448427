import { Container, Row, Col} from "react-bootstrap";
import { socialNetworks, logos, socialLink } from '../data/data';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={3}>
            <img className="image-logo" src={ logos.tecnotorq } loading="lazy" alt="Logo"/>
          </Col>

          <Col size={12} sm={3} className="info-contact">
            TECNOTORQ SAS
            <p>Los almendros</p>
            <p>Medellin Colombia</p>
            <p >+57 3143658830</p>
            <p>eduardo@tecnotorq.com</p>
          </Col>
          
          <Col size={12} sm={3}>
            <div className="social-icon">
              <a href={ socialLink.instagram } target="_blank" rel="noreferrer">
                <img src={ socialNetworks.instagram } loading="lazy" alt="Instagram"/>
              </a>
              <a href={socialLink.whatsApp} target="_blank" rel="noreferrer">
                <img src={ socialNetworks.whatsApp } loading="lazy" alt="WhatsApp" />
              </a>
              <a href={ socialLink.linkedin } target="_blank" rel="noreferrer">
                <img src={ socialNetworks.linkedin } loading="lazy" alt="LinkedIn"/>
              </a>
              <a href={ socialLink.facebook } target="_blank" rel="noreferrer">
                <img src={ socialNetworks.facebook } loading="lazy" alt="Facebook"/>
              </a>
            </div>
          </Col>
          <Col size={12} sm={3} className="text-center text-sm-end">
            <p className="copyright">Copyright {new Date().getFullYear()}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
