import { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import TrackVisibility from 'react-on-screen';
import 'animate.css';

import emailjs from '@emailjs/browser';

import { logos } from '../data/data';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    company: 'Tecnotorq',
  }

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});
  const form = useRef();

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    setButtonText("Enviando...");
    
    emailjs
    .sendForm('service_7o19ebc', 'template_i7xw0rl', form.current, {
      publicKey: '8idDeey6xH1Caczug',
    })
    .then(
      () => {
        setStatus({ succes: true, message: 'Mensaje enviado correctamente'});
        setFormDetails(formInitialDetails);
      },
      (error) => {
        setStatus({ succes: false, message: error.text});
      },
    )

    setButtonText("Enviar");
  };

  return (
    <section className="contact" id="contact">
      <Container className="container-contact">
        <Row className="align-items-center row-contact">
          <Col size={12} md={6}>
            <img src={ logos.contact } loading="lazy" alt="Contact Us"/>
              
            {/* <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__fadeInLeft" : ""} src={ logos.contact } alt="Contact Us"/>
              }
            </TrackVisibility> */}
          </Col>
          <Col size={12} md={6}>
            {/* <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeInRight" : ""}> */}
                <h2>Ponerse en contacto</h2>
                <form ref={ form } onSubmit={ handleSubmit }>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.firstName} name="firstName" placeholder="Nombres" onChange={(e) => onFormUpdate('firstName', e.target.value)} required/>
                      <input type="hidden" value={formDetails.company} name="company" onChange={(e) => onFormUpdate('company', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.lastName} name="lastName" placeholder="Apellidos" onChange={(e) => onFormUpdate('lastName', e.target.value)} required/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={formDetails.email} name="email" placeholder="Correo" onChange={(e) => onFormUpdate('email', e.target.value)} required/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} name="phone" placeholder="Celular." onChange={(e) => onFormUpdate('phone', e.target.value)} required/>
                    </Col>
                    <Col size={12} sm={12} className="px-1">
                      <textarea rows="4" value={formDetails.message} name="message" placeholder="Mensaje" onChange={(e) => onFormUpdate('message', e.target.value)} required></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col size={12} sm={12} className="px-1 mt-3">
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              {/* </div>}
            </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
