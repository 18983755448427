import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import 'animate.css';
//import TrackVisibility from 'react-on-screen';

import { CardsProduct } from "./CardsProduct";
import { tools, cajas, cilindros } from '../data/data';

export const Products = () => {
  return (
    <section className="product" id="product">
      <Container className="container-product">
        <Row className="row-product">
          <Col size={12}>
            {/* <TrackVisibility> */}
              {/* {({ isVisible }) => */}
              {/* <div className={ isVisible ? "animate__animated animate__fadeIn": "" }> */}
                <h2>Productos</h2>
                <p>
                  En <strong>Tecnotorq</strong>, ofrecemos una amplia selección de productos de la más alta calidad y servicio excepcional a nuestros clientes. Trabajamos estrechamente con fabricantes y proveedores de confianza para garantizar la disponibilidad y fiabilidad de nuestros productos, ven descubre nuestra amplia gama de productos y encuentra la solución perfecta.
                </p>
                <Tab.Container id="products-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Herramientas de ensamble</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Ascesorios</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Servicios de mantenimiento</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" /*className={ isVisible ? "animate__animated animate__fadeInRight" : ""}*/>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          tools.map(( tool ) => {
                            return (
                              <CardsProduct
                                key={ tool.id }
                                { ...tool }
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          cajas.map(( caja ) => {
                            return (
                              <CardsProduct
                                key={ caja.id }
                                { ...caja }
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          cilindros.map(( cilindro ) => {
                            return (
                              <CardsProduct
                                key={ cilindro.id }
                                { ...cilindro }
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              {/* </div>} */}
            {/* </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
