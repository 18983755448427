import React from 'react'
import 'animate.css';
import 'react-multi-carousel/lib/styles.css';
import { CarruselServices } from './CarruselServices';

export const Services = () => {

  return (
    <section className="service" id="service">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="client-bx">
              <h2>Nuestros servicios</h2>
              <CarruselServices />
            </div>
          </div>
        </div>
      </div>  
    </section>
  )
}
