import React from 'react';
import {Row, Col } from "react-bootstrap";
import 'animate.css';

import { Carrusel } from './Carrusel';
import { Clients } from './Clients';
import { logos } from '../data/data';
// import { NewCarrusel } from './NewCarrusel';

export const Home = () => {
 
  return (
    <section className="home" id="home">
      <Row className="aligh-items-center" id="row-carrusel">
        <Carrusel/>
      </Row>
      <Row className="distribuidor-autorizado justify-content-center">
        <Col className='d-flex justify-content-center align-items-center' sm={4}>
          {/* <h5 className='tetx-distribuidor'>Somos distribuidores</h5> */}
        </Col>

        <Col className='d-flex justify-content-center align-items-center' sm={4}>
          <img className="atlas-copco-distribuidor" src={ logos.atlascopco } loading="lazy" alt="Logo"/>
        </Col>
        
        <Col className='d-flex justify-content-center align-items-center' sm={4}>
          {/* <h5 className='tetx-distribuidor'>autorizados</h5> */}
        </Col>
      </Row>
      
      <Clients/>
    </section>
  )
}
