import tecnotorq from '../assets/images/logo/logoTecnotorq.png';
import atlascopco from "../assets/images/logo/atlasCopco.png";
import contact from "../assets/images/logo/contact.png";

import akt from "../assets/images/brand/logoAkt.png";
import suzuki from "../assets/images/brand/logoSuzuki.png";
import yamaha from "../assets/images/brand/logoYamaha.png";

import img1 from "../assets/images/home/BCV.jpg";
import img2 from "../assets/images/home/IMG_6368.jpg";
import img3 from "../assets/images/home/scania.jpg";
import img4 from "../assets/images/home/LSV12.jpg";
import img5 from "../assets/images/home/Saltus.jpg";
import img6 from "../assets/images/home/Tensor.jpg";
import img7 from "../assets/images/home/GTG25.jpg";
import img8 from "../assets/images/home/Industrial.jpg";

import linkedin from '../assets/images/socialNetworks/imageLinkedIn.svg';
import whatsApp from '../assets/images/socialNetworks/imageWhatsApp.svg';
import instagram from '../assets/images/socialNetworks/imageInstagram.svg';
import facebook from '../assets/images/socialNetworks/facebook.svg';
import email from '../assets/images/socialNetworks/email.svg';

import herramienta1 from "../assets/images/Product/herramienta1.jpg";
import herramienta2 from "../assets/images/Product/herramienta2.jpg";
import herramienta3 from "../assets/images/Product/herramienta3.jpg";
import herramienta4 from "../assets/images/Product/herramienta4.jpg";

import product4 from "../assets/images/imagen4.png";
import product5 from "../assets/images/imagen5.png";
import product6 from "../assets/images/imagen6.png";

import service1 from "../assets/images/Product/Services/service1.jpeg";
import service2 from "../assets/images/Product/Services/service2.jpeg";
import service3 from "../assets/images/Product/Services/service3.jpeg";

const tools = [
    {
        id: 1,
        title: "Torquímetros",
        description: "Torquímetros amplio rango desde 2 Nm hasta 300 Nm. Ajuste, manejo fácil y seguro. Unidad estándar 9x12 o 14x18 para una amplia gama determinales adecuados.",
        imgUrl: herramienta1,
    },
    {
        id: 2,
        title: "Herramientas a bateria",
        description: "Herramientas a batería con embrague directo y pulsativo, control de torque, ángulo y trazabilidad “error proofing” (Poka Yoke)",
        imgUrl: herramienta2,
    },
    {
        id: 3,
        title: "Herramientas neumaticas",
        description: "Herramientas neumaticas con embrague directo y pulsativo para ambientes con alta y baja presión con o sin lubricación.",
        imgUrl: herramienta3,
    },
    {
        id: 4,
        title: "Accesorios de estación",
        description: "Puntas imantadas, extensiones, yoyos, acoples y toda la infraestructura necesaria para mejorar la productividad, calidad y ergonomía de su estación de trabajo.",
        imgUrl: herramienta4,
    },
];
  
const cajas = [
    {
        id: 1,
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: product4,
    },
    {
        id: 2,
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: product5,
    },
    {
        id: 3,
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: product6,
    },
];

const cilindros = [
    {
        id: 1,
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: service2,
    },
    {
        id: 2,
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: service1,
    },
    {
        id: 3,
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: service3,
    },
];
const socialLink = {
    facebook: '',
    whatsApp: 'https://wa.link/fbh8m4',
    instagram: 'https://www.instagram.com/tecnotorq/',
    linkedin: 'https://www.linkedin.com/in/eduardo-andres-sanchez-sepulveda-67778840/'
};
const socialNetworks = {
    facebook: facebook,
    whatsApp: whatsApp,
    instagram: instagram,
    linkedin: linkedin,
    email: email,
};
const logos = {
    tecnotorq: tecnotorq,
    atlascopco: atlascopco,
    contact: contact,
};
const brands = {
    akt: akt,
    suzuki: suzuki,
    yamaha: yamaha,
};

const imagesHome = {
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
};
const imagesHome2 = [
    {
        id: 1,
        title: "Torquímetros",
        description: "Torquímetros amplio rango desde 2 Nm hasta 300 Nm. Ajuste, manejo fácil y seguro. Unidad estándar 9x12 o 14x18 para una amplia gama determinales adecuados.",
        imgUrl: img1,
    },
    {
        id: 2,
        title: "Herramientas a bateria",
        description: "Herramientas a batería con embrague directo y pulsativo, control de torque, ángulo y trazabilidad “error proofing” (Poka Yoke)",
        imgUrl: img2,
    },
    {
        id: 3,
        title: "Herramientas neumaticas",
        description: "Herramientas neumaticas con embrague directo y pulsativo para ambientes con alta y baja presión con o sin lubricación.",
        imgUrl: img3,
    },
    {
        id: 4,
        title: "Accesorios de estación",
        description: "Puntas imantadas, extensiones, yoyos, acoples y toda la infraestructura necesaria para mejorar la productividad, calidad y ergonomía de su estación de trabajo.",
        imgUrl: img4,
    },
]
const imagesHome3 = [
    {
        id: 1,
        imgUrl: img1,
    },
    {
        id: 2,
        imgUrl: img2,
    },
    {
        id: 3,
        imgUrl: img3,
    },
    {
        id: 4,
        imgUrl: img4,
    },
    {
        id: 5,
        imgUrl: img5,
    },
    {
        id: 6,
        imgUrl: img6,
    },
    {
        id: 7,
        imgUrl: img7,
    },
    {
        id: 8,
        imgUrl: img8,
    },
    
]

export { tools, cajas, cilindros, socialNetworks, logos, brands, imagesHome, imagesHome2, imagesHome3, socialLink };